import React from 'react';
import { css } from 'styled-components';
import { AnimatedWebflow } from 'components/animated-webflow';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { FooterSection } from 'modules/footer-section';
import { MainSection } from 'modules/main-section';
import { ObjectiveAdvantagesSection } from 'modules/objective-advantages-section';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { array } from 'prop-types';

import { BenefitsSection } from './benefits-section';
import { CommitmentSection } from './commitment-section';
import { ADVANTAGES, PERKS } from './constants';
import { WebflowAgencyCtaSection } from './cta-section';
import { ExpertiseSection } from './expertise-section';
import { SuccessStoriesSection } from './success-stories-section';
import { WhyWebflowSection } from './why-webflow-section';
const Props = {
    faqData: array.isRequired,
};

export const WebflowAgencyView = ({ faqData }) => {
    return (
        <div>
            <MainSection
                icon={<AnimatedWebflow />}
                iconWrapperCss={css`
                    max-width: 34.25rem;
                `}
                titleId="view-webflow-agency.main-section.title"
                subtitles={['view-webflow-agency.main-section.subtitle']}
                estimateProjectAnalytics={
                    gaTrackedEvents.WEBFLOW_AGENCY.CTA.ESTIMATE_PROJECT
                }
                intoCallAnalytics={
                    gaTrackedEvents.WEBFLOW_AGENCY.CTA.INTRO_CALL
                }
            />
            <CommitmentSection />
            <WhyWebflowSection />
            <BenefitsSection />
            <AIAssistantSection />
            <ObjectiveAdvantagesSection
                title="view-webflow-agency.advantages-section.title"
                subTitle="view-webflow-agency.advantages-section.description"
                rootPath="view-webflow-agency.advantages-section"
                data={ADVANTAGES}
            />
            <ExpertiseSection />
            <WebflowAgencyCtaSection />
            <SuccessStoriesSection />
            <PerksWithImageSection
                title="view-webflow-agency.perks-section.title"
                description="view-webflow-agency.perks-section.description"
                alt="view-webflow-agency.perks-section.alt"
                perks={PERKS}
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                isArticlesSectionVisible={false}
            />
        </div>
    );
};

WebflowAgencyView.propTypes = Props;
